import React, { useState, useEffect } from "react";
// import { Auth, Hub } from "aws-amplify";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

import theme from "assets/theme";
import themeRTL from "assets/theme/theme-rtl";

import { authRoutes } from "routes";
import Main from "layouts/Main/Main";
import { toast, ToastContainer } from "react-toastify";
import { setDarkMode, setDirection, useMaterialUIController } from "context";
import { useAppDispatch, useAppSelector } from "store/hooks";
import i18n from "services/i18n/i18n";
import { updateSelectedLangauge } from "store/slices/config";
import { CacheProvider } from "@emotion/react";
import rtlPlugin from "stylis-plugin-rtl";
import { useMemo } from "react";
import createCache from "@emotion/cache";
import "./customStyles.css";
import useIsMobile from "hooks/use-is-mobile.hook";
import useAppAuth from "appAuth.hook";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { selectLoggedUser } from "store/slices/users-data";

const PORTAL_MESSAGES_KEY = "portalMessagesSeen";

const queryClient = new QueryClient();

export default function App() {
  const { pathname } = useLocation();
  const [portalMessagesSeen, setPortalMessagesSeen] = useState<boolean>(false);
  const { isLoggedIn } = useAppAuth();
  const appDispatch = useAppDispatch();
  const userData = useAppSelector(selectLoggedUser);
  const [controller, dispatch] = useMaterialUIController();
  const { darkMode, direction } = controller;

  useIsMobile();

  // Cache for the rtl
  const cacheRtl = useMemo(() => {
    if (direction === 'rtl') {
      const pluginRtl: any = rtlPlugin;
      return createCache({
        key: "rtl",
        stylisPlugins: [pluginRtl],
      });
    } else {
      return createCache({ key: 'css' });
    }
  }, []);

  useEffect(() => {
    const seen = localStorage.getItem(PORTAL_MESSAGES_KEY);
    if (seen === "true") {
      setPortalMessagesSeen(true);
    }
    document.dir = 'ltr';
    document.body.setAttribute("dir", "ltr");
    updateLangugeSettings();
  }, []);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const updateLangugeSettings = () => {
    const localLanguage = localStorage.getItem("language");
    let lang = "en";
    if (localLanguage) {
      lang = localLanguage;
    } else {
      localStorage.setItem("language", lang);
    }
    appDispatch(updateSelectedLangauge({ selectedLanguage: lang }));
    setDirection(dispatch, "ltr");
    i18n.changeLanguage(lang);
  };

  const getLoggedUserRoute = (): React.ReactElement => {
    return <Main />;
  };

  const renderBody = () => {
    return (
      <React.Fragment>
        <CssBaseline />
        <Routes>
          <Route
            path="*"
            element={
              isLoggedIn ? (
                getLoggedUserRoute()
              ) : (isLoggedIn as boolean) === false ? (
                <Navigate to="/login" />
              ) : (
                <LoadingSpinner />
              )
            }
          />
          {!isLoggedIn &&
            // !isBackofficeUser &&
            authRoutes.map((route) => (
              <Route
                key={route.key}
                path={route.key}
                element={route.component}
              />
            ))}
          <Route />
        </Routes>
        <ToastContainer
          theme={(darkMode && "dark") || "light"}
          position={
            direction === "rtl"
              ? toast.POSITION.TOP_CENTER
              : toast.POSITION.TOP_RIGHT
          }
          toastClassName="dark-toast"
          style={{ fontSize: "14px", fontFamily: "Roboto" }}
        />
      </React.Fragment>
    );
  };

  return (
    <QueryClientProvider client={queryClient}>
      <CacheProvider value={cacheRtl}>
        <ThemeProvider theme={direction === 'rtl' ? themeRTL : theme}>{renderBody()}</ThemeProvider>
      </CacheProvider>
    </QueryClientProvider>
  );
}
