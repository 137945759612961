import * as React from "react";
import Autocomplete from "@mui/material/Autocomplete";
import { Popper, SxProps, TextField, Theme } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { makeStyles } from "@mui/styles";
import { SelectItem } from "types/select";
import { t } from "i18next";

const useStyles = makeStyles((theme: Theme) => ({
  root: {},
  inputClass: {
    padding: `0 10px 0 0 !important`,
  },
}));

export default function SelectInput({
  value,
  onChange,
  options,
  minWidth,
  placeholder,
  label,
  style = {},
  disableClearable = true,
  noOptionsText,
  disabled,
  InputLabelProps,
  InputPropsSx,
  arrowIconColor,
  TextFieldPropsSx,
  variant,
  sx,
}: {
  value?: SelectItem;
  options: SelectItem[];
  onChange: (val: string, option?: SelectItem) => void;
  minWidth?: number;
  style?: React.CSSProperties;
  placeholder?: string;
  label?: string;
  disableClearable?: boolean;
  noOptionsText?: string;
  disabled?: boolean;
  InputLabelProps?: any;
  InputPropsSx?: any;
  TextFieldPropsSx?: any;
  arrowIconColor?: any;
  variant?: any;
  sx?: any;
}) {
  const classes = useStyles();

  const CustomPopper = function (props: any) {
    return <Popper {...props} style={{}} placement="bottom-end" />;
  };

  return (
    <Autocomplete
      noOptionsText={noOptionsText || t("common:noResults")}
      disablePortal
      PopperComponent={CustomPopper}
      disableClearable={disableClearable}
      disabled={disabled}
      id="select-component"
      options={options}
      classes={{ root: classes.root }}
      defaultValue={
        typeof value == "string"
          ? options.find((opt) => opt.value == value)
          : value || null
      }
      onChange={(e, val) => {
        if (typeof val == "string") {
          onChange(val);
        } else {
          onChange(val?.value?.toString(), val);
        }
      }}
      getOptionLabel={(opt: any) => (opt as SelectItem)?.label || opt}
      style={{
        padding: 0,
        minWidth: minWidth || 110,
      }}
      sx={sx}
      popupIcon={
        <KeyboardArrowDownIcon
          sx={{ opacity: disabled ? 0.5 : 1 }}
          color={arrowIconColor || ("text" as any)}
        />
      }
      renderInput={(params) => (
        <TextField
          {...params}
          variant={variant}
          label={label}
          placeholder={placeholder}
          title={params?.inputProps?.value.toString() || ""}
          sx={{
            "& .MuiInputBase-input": {
              textOverflow: "ellipsis",
              display: "flex",
              whiteSpace: "nowrap",
              width: "calc(100% - 62px)",
              overflow: "hidden",
            },
            "& .MuiAutocomplete-endAdornment": {
              position: "relative",
              right: "3px!important",
              top: 0,
            },
            ...TextFieldPropsSx,
          }}
          InputLabelProps={{ shrink: true, ...InputLabelProps }}
          InputProps={{
            classes: {
              root: classes.root,
              input: classes.inputClass,
            },
            style: {
              padding: "0 0 0 5px",
              ...style,
            },
            sx: {
              paddingTop: "0px !important",
              paddingBottom: "0px !important",
              ...InputPropsSx,
            },
            ...params.InputProps,
          }}
        />
      )}
    />
  );
}
