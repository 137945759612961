import {
  useState,
  useEffect,
  JSXElementConstructor,
  Key,
  ReactElement,
} from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";
// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";

//  Dark Mode themes
import themeDark from "assets/theme-dark";

import routes from "routes";

//  contexts
import { useMaterialUIController, setMiniSidenav } from "context";

// Images
import brandLogo from "assets/images/logos/Logo.svg";
import Sidenav from "components/Sidenav";
import { ConfirmDialogProvider } from "components/ConfirmDialog";
import { useAppDispatch, useAppSelector } from "store/hooks";
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner";
import { getIsMobile } from "store/slices/general";
import MDBox from "components/MDBox";
import { fetchAllAdvertisers, fetchCountries } from "store/slices/config";
import { fetchNotifications } from "store/slices/notifications";
import { globalColors } from "assets/colors-global";

export default function App() {
  const appDispatch = useAppDispatch();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, direction, layout, sidenavColor, darkMode } = controller;
  const [onMouseEnter, setOnMouseEnter] = useState(false);
  const [isUserAssosiated, setIsUserAssosiated] = useState(false);
  const { pathname } = useLocation();
  const isMobile = useAppSelector(getIsMobile);

  // Open sidenav when mouse enter on mini sidenav
  const handleOnMouseEnter = () => {
    if (miniSidenav && !onMouseEnter) {
      setMiniSidenav(dispatch, false);
      setOnMouseEnter(true);
    }
  };

  // Close sidenav when mouse leave mini sidenav
  const handleOnMouseLeave = () => {
    if (onMouseEnter) {
      setMiniSidenav(dispatch, true);
      setOnMouseEnter(false);
    }
  };


  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  // useEffect(() => {
  //   appDispatch(fetchNotifications());
  //   const timer = setInterval(() => {
  //     appDispatch(fetchNotifications());
  //   }, 10_000);

  //   return () => clearInterval(timer);
  // },[])

  const getRoutes = (allRoutes: any[], isUserAssosiated: boolean): any => {
    return allRoutes.map(
      (route: {
        collapse: any;
        route: string;
        component: ReactElement<any, string | JSXElementConstructor<any>>;
        key: Key;
        shouldBeAssosiated?: boolean;
      }) => {
        if (route.collapse?.length) {
          return getRoutes(route.collapse, isUserAssosiated);
        }
        if (
          (route.route && !route.shouldBeAssosiated) ||
          (route.route && route.shouldBeAssosiated && isUserAssosiated)
        ) {
          return (
            <Route
              path={route.route}
              element={route.component}
              key={route.key}
            />
          );
        }

        return null;
      }
    );
  };

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      {true ? (
        // {isBackofficeUser || userClientId ? (
        <MDBox bgColor="white">
          {layout === "dashboard" && (
            <>
              <Sidenav
                color={sidenavColor}
                brand={brandLogo}
                routes={routes(isMobile)}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
              />
            </>
          )}
          <ConfirmDialogProvider>
            <Routes>
              {getRoutes(routes(isMobile), isUserAssosiated)}
              <Route
                path="*"
                element={
                  <Navigate
                    to={
                      // handle redirection to correct page
                      "/home"
                    }
                  />
                }
              />
            </Routes>
          </ConfirmDialogProvider>
        </MDBox>
      ) : (
        <LoadingSpinner />
      )}
    </ThemeProvider>
  );
}
