import React, { useCallback } from 'react';
import {
  OutlinedTextFieldProps,
  StandardTextFieldProps,
} from "@mui/material";
import MDInput from "components/MDInput";
// import SearchIcon from "@mui/icons-material/Search";
import { FC, useState } from "react";
import { debounce } from 'lodash';

type InputType = OutlinedTextFieldProps | StandardTextFieldProps;
type Props = InputType & {
  onSearchChanged: (value: string) => void;
  // regex: RegExp;
};

const SearchInput: FC<Props> = ({ onSearchChanged, ...rest }) => {
  const [searchText, setSearchText] = useState("");

  const handleDebounceFn = (value: string) => {
    if (value.length >= 2) {
      onSearchChanged(value);
    }
    // in case of reset search
    if (value === "") {
      onSearchChanged("");
    }
  }
  const debounceFn = useCallback(debounce(handleDebounceFn, 400), []);
  
  const handleSearchOnChange = (event: any) => {
    const value = event.target.value;
    setSearchText(value);
    debounceFn(value);
  };

  return (
    <MDInput
      size="small"
      onChange={handleSearchOnChange}
      // InputProps={{
      //   sx: { paddingRight: "4px !important" },
      //   endAdornment: (
      //     <InputAdornment position="end">
      //       <IconButton onClick={handleSearchIconClick}>
      //         <SearchIcon fontSize="small" />
      //       </IconButton>
      //     </InputAdornment>
      //   ),
      // }}
      value={searchText}
      {...rest}
    />
  );
};

export default SearchInput;
