import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import PageContentContainer from "components/PageContainers/PageContentContainer";
import PageHeaderContainer from "components/PageContainers/PageHeaderContainer";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import BOTable from "components/BOTable/BOTable";
import { Tables } from "constants/tables";
import { ApiSearchResult } from "types/api";
import { downloadFile } from "utils/download.utils";
import colors from "assets/theme/base/colors";

const fileURL = 'https://app.minereye.com/boards/minereye-boards.json';
const BoardsPage = () => {
  const { t } = useTranslation();

  const onRowClicked = useCallback(async (cell?: any) => {
    if (cell?.row?.original && cell?.column?.id === 'download') {
      const res = await fetch(cell?.row?.original.board_download_url);
      downloadFile(await res.text(), `${cell?.row?.original.board_name}.json`);
    }
  }, []);

  return (
    <DashboardLayout>
      <PageHeaderContainer>
        <DashboardNavbar pageTitle={t("boards:title")} />
      </PageHeaderContainer>
      <PageContentContainer component="div">
        <MDBox p={0.5} shadow="md">
        <BOTable
            tableWrapperStyle={{
              border: `1px solid ${colors.grey[100]}`
            }}
            table={Tables.BOARDS}
            height="100%"
            tableKey="boards"
            onRowClicked={onRowClicked}
            fetchNextPage={async (): Promise<ApiSearchResult<unknown>> => {
              const res = await fetch(fileURL).then(async r => {
                return JSON.parse(await r.text())
              });
              return Promise.resolve({
                data: res || [],
                meta: { totalRowCount: res.count, page: 1 }
              });
          } }
          />
        </MDBox>
      </PageContentContainer>
    </DashboardLayout>
  );
};

export default BoardsPage;
