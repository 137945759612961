export const sortArrayByOrder = <T>(arr: T[], order: string[], sortField: keyof T): T[] => {
  const orderMap = new Map();
  order.forEach((id, index) => orderMap.set(id, index));
  const result = [...arr].sort((a, b) => {
    const aIndex = orderMap.get(String(a[sortField]));
    const bIndex = orderMap.get(String(b[sortField]));
    if (aIndex !== undefined && bIndex !== undefined) {
      return aIndex - bIndex;
    } else if (aIndex !== undefined) {
      return -1;
    } else if (bIndex !== undefined) {
      return 1;
    } else {
      return 0;
    }
  });
  return result;
}