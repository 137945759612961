import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import SearchInput from "components/SearchInput";
import Table from "components/TanstackTable/Table";
import { ReactComponent as ExcelIcon } from "assets/Icons/excelIcon.svg";
import { ReactComponent as ClearFilter } from "assets/Icons/clearFilter.svg";
import { ApiSearchQueryParams, ApiSearchResult } from "types/api";
import { CSSProperties, ReactNode, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { IconButton, styled, useTheme } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { exportFile } from "utils/download.utils";
import { ExportFileType } from "enums/export-file-type.enum";
import { ExportColumn } from "types/export-column";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import FiltersRow, {
  FilterType,
  FiltersConfig,
} from "../Tables/Filters/Filters";
import MDButton from "components/MDButton";
import _ from "lodash";
import useWindowResize from "hooks/use-window-resize";
import { getExportFilters } from "components/TanstackTable/table.utils";
import { firstCharToLowerCase } from "utils/string.utils";
import { useAppSelector } from "store/hooks";
import { getIsMobile } from "store/slices/general";
import SaveIcon from "@mui/icons-material/Save";
import CloseIcon from "@mui/icons-material/Close";
import RefreshIcon from "@mui/icons-material/SettingsBackupRestore";
import EditIcon from "@mui/icons-material/Edit";
import { Tables, tablesConfigs } from "constants/tables";
import { TableConfig } from "interfaces/table";
import { sortArrayByOrder } from "utils/array.util";
import { ALL_OPTION } from "utils/select.utils";
import { Table as TanstackTable } from "@tanstack/react-table";

type BOTableProps<DataEntity> = {
  table: Tables;
  pageSize?: number;
  tableKey: string;
  searchPlaceholder?: string;
  searchRegex?: RegExp;
  filtersConfig?: FiltersConfig;
  initialFiltersState?: any;
  onFilterChanged?: (filters: any) => void;
  showClear?: boolean;
  showExport?: boolean;
  additionalInfo?: ReactNode;
  noResultMsg?: string;
  editable?: boolean;
  tableWrapperStyle?: CSSProperties;
  fetchNextPage: (
    searchParams: ApiSearchQueryParams,
    filters?: any
  ) => Promise<ApiSearchResult<DataEntity>>;
  isReset?: boolean;
  onRowClicked?: (cell: any) => void;
  onInit?: (v: { refetch: () => void, table: TanstackTable<any> }) => void;
  height?: string;
};

const BOTable = <DataEntity extends unknown>({
  table,
  pageSize = 20,
  tableKey,
  searchPlaceholder,
  filtersConfig,
  onFilterChanged,
  initialFiltersState,
  showClear,
  isReset,
  showExport = false,
  additionalInfo,
  noResultMsg,
  editable,
  height = 'calc(100vh - 190px)',
  tableWrapperStyle,
  onRowClicked,
  fetchNextPage,
  onInit,
}: BOTableProps<DataEntity>) => {
  const isMobile = useAppSelector(getIsMobile);
  const [isEditingTableMode, setIsEditingTableMode] = useState(false);
  const [searchTxt, setSearchTxt] = useState("");
  const [headerHeight, setHeaderHeight] = useState(0);
  const [searchQueryParams, setSearchQueryParams] =
    useState<ApiSearchQueryParams>();
  const [noResult, setNoResult] = useState<boolean>(false);
  const [currentPageRes, setCurrentPageRes] =
    useState<ApiSearchResult<DataEntity>>();
  const [noData, setNoData] = useState<boolean>(false);
  const [shouldClear, setShouldClear] = useState<boolean>(false);
  const [filters, setFilters] = useState<any>(initialFiltersState || {});
  const windowSize = useWindowResize();
  const ref = useRef<HTMLElement>();
  const theme = useTheme();
  const tableHeaderElRef = useRef(null);
  const { t } = useTranslation();

  const tableConfig = useMemo(() => {
    const savedConfigs = localStorage.getItem(table);
    let config: TableConfig = null;
    try {
      config = JSON.parse(savedConfigs);
    } catch {}
    return config || tablesConfigs[table];
  }, [table, isEditingTableMode]);
  
  const [newTableSettings, setNewTableSettings] = useState<TableConfig | null>(tableConfig);
  

  const handleExportToExcel = () => {
    const cols = Object.entries(tableConfig.columns).map(([key, value]) => ({
      accessorKey: key,
      size: value.size,
    }));
    const columnArray = sortArrayByOrder(cols, tableConfig.order, 'accessorKey');

    const exportColumns: ExportColumn[] = columnArray
      .filter((col: any) => col.accessorKey != "actions")
      .map((x: any) => ({
        header: t(`${firstCharToLowerCase(tableKey)}:${x.accessorKey}`),
        width: (x.size || 80) / 5,
        key: x.accessorKey,
      }));
    const exportFilters = getExportFilters(
      filtersConfig,
      filters,
      searchPlaceholder,
      searchTxt,
      t
    );
    exportFile(
      tableKey,
      ExportFileType.EXCEL,
      exportColumns,
      exportFilters,
      searchQueryParams,
      { ...filters },
      t(`${firstCharToLowerCase(tableKey)}:exportTitle`)
    );
  };

  const updateHeaderHeight = () => {
    const headerEl = tableHeaderElRef?.current;
    if (headerEl) {
      setHeaderHeight(headerEl.getBoundingClientRect().height);
    }
  };

  useEffect(() => {
    updateHeaderHeight();
  }, [windowSize]);

  useEffect(() => {
    setTimeout(() => {
      updateHeaderHeight();
    }, 1000);
  }, []);

  const handleFetchNextPage = async (
    searchParams: ApiSearchQueryParams,
    filters?: any
  ) => {
    setSearchQueryParams(searchParams);
    const res = await fetchNextPage(searchParams, filters);
    setEmptyStates(res.meta.totalRowCount, searchParams, filters);
    setCurrentPageRes(res);
    return res;
  };

  const setEmptyStates = (
    totalRowsRes: number,
    searchParams: ApiSearchQueryParams,
    filters: any = {}
  ) => {
    if (totalRowsRes == 0 && searchParams.page == 1) {
      if (
        searchParams.search ||
        Object.values(filters).find((filterValue) => !!filterValue)
      ) {
        setNoResult(true);
        setNoData(false);
      } else {
        setNoData(true);
        setNoResult(false);
      }
    } else {
      setNoResult(false);
      setNoData(false);
    }
  };

  const exportData = async () => {
    var doc = new jsPDF();

    // // Source HTMLElement or a string containing HTML.
    // var elementHTML = document.querySelector("#contnet");

    const canvas = await html2canvas(ref.current, {
      scale: 5, // 2480 / ref.current.clientWidth, // 2480px - size for A4 paper, 300 dpi
    });
    doc.addImage(canvas.toDataURL("image/jpeg", 1.0), "JPG", 0, 0, 200, 200);
    doc.save("sample-document.pdf");
  };

  const onFilterChange = (value: any, name: string) => {
    if ((value[0]?.value || value[0]) === ALL_OPTION.value) {
      // for "all" option reset specific filter
      setFilters({ ...filters, [name]: [] });
      onFilterChanged && onFilterChanged({ ...filters, [name]: [] });
    } else {
      setFilters({ ...filters, [name]: value });
      onFilterChanged && onFilterChanged({ ...filters, [name]: value });
    }
  };

  const resetFilters = () => {
    setFilters(initialFiltersState);
  };

  const setEditingForm = useCallback(() => {
    setIsEditingTableMode(true);
  }, []);

  const saveForm = useCallback(() => {
    localStorage.setItem(table, JSON.stringify(newTableSettings));
    setIsEditingTableMode(false);
  }, [newTableSettings, table]);

  const cancelEditing = useCallback(() => {
    setNewTableSettings({ ...tableConfig });
    setIsEditingTableMode(false);
  }, [tableConfig]);

  const resetEditing = useCallback(() => {
    localStorage.setItem(table, JSON.stringify(tablesConfigs[table]));
    setNewTableSettings(tablesConfigs[table]);
    setIsEditingTableMode(false);
  }, [table]);

  if (!table) return null;
  return (
    <MDBox height={`100%`} ref={ref}>
      <MDBox ref={tableHeaderElRef} pb={1} position="relative">
        <MDBox display={"flex"} alignItems="center" key={`${shouldClear}`}>
          {searchPlaceholder && (
            <SearchInput
              onSearchChanged={setSearchTxt}
              placeholder={searchPlaceholder}
            />
          )}
          {filtersConfig && (
            <FiltersRow
              filters={filters}
              onFilterChange={onFilterChange}
              config={filtersConfig}
            />
          )}
          <div style={{ flex: 1 }} />
          {showClear && (
            <MDButton
              variant="outlined"
              color="primary"
              style={{
                padding: "0px 10px",
                height: 32,
                minHeight: 32,
                borderColor: (theme.palette.primary as any).text,
              }}
              onClick={() => {
                setShouldClear(!shouldClear);
                setSearchTxt("");
                resetFilters();
              }}
            >
              <ClearFilter />
              <MDTypography color="primary" fontWeight="bold" fontSize={12}>
                {t("common:clear")}
              </MDTypography>
            </MDButton>
          )}

          {editable ? <MDBox display={"flex"} flexDirection="row-reverse">
            {isEditingTableMode ? 
              <>
                <MDButton
                  sx={{ marginLeft: pxToRem(8), height: 38 }}
                  size="small"
                  onClick={saveForm}
                  color="secondary"
                  
                  startIcon={<SaveIcon />}
                >{t('tables:saveTable')}</MDButton>
                <MDButton
                  sx={{ marginLeft: pxToRem(8), height: 38 }}
                  size="small"
                  onClick={cancelEditing}
                  color="primary"
                  variant="outlined"
                  startIcon={<CloseIcon />}
                >{t('tables:cancelTableSettings')}</MDButton>
                <MDButton
                  sx={{ marginLeft: pxToRem(8), height: 38 }}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={resetEditing}
                  startIcon={<RefreshIcon />}
                >{t('tables:resetTableSettings')}</MDButton>
              </>
              : <MDButton
                  sx={{ marginLeft: pxToRem(8), height: 38 }}
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={setEditingForm}
                  startIcon={<EditIcon />}
                >{t('tables:editTable')}</MDButton>
            }
          </MDBox> : null}
        </MDBox>
        {additionalInfo}
        <MDBox
          sx={
            isMobile
              ? {
                  display: "flex",
                  alignItems: "baseline",
                  flexDirection: "row-reverse",
                  justifyContent: "space-between",
                }
              : {}
          }
        >
          {showExport && (
            <MDBox display="flex" justifyContent="flex-end">
              {/* <StyledIconButton color="primary">
              <PrinterIcon />
            </StyledIconButton> */}
              <StyledIconButton color="primary" onClick={handleExportToExcel}>
                <ExcelIcon />
              </StyledIconButton>
              {/* <StyledIconButton color="primary" onClick={exportData}>
              <PdfIcon />
            </StyledIconButton> */}
            </MDBox>
          )}
        </MDBox>
      </MDBox>
      <MDBox
        height={height}
        mt={1}
        style={tableWrapperStyle}
        bgColor="white"
        overflow="hidden"
        borderRadius="xl"
        shadow="md"
        position="relative"
      >
        <Table<DataEntity>
          editMode={isEditingTableMode}
          tableConfig={newTableSettings}
          searchString={searchTxt}
          fetchData={handleFetchNextPage}
          onSettingsUpdate={setNewTableSettings}
          fetchSize={pageSize}
          tableKey={tableKey}
          filters={filters}
          isReset={isReset}
          striped
          onRowClick={onRowClicked}
          onInit={onInit}
          noData={noResult || noData}
        />
        {noResult && (
          <MDBox position="absolute" top="80px">
            <MDTypography ml={2} variant="caption" color="primary" my={2}>
              {noResultMsg || t("general:no_result_msg")}
            </MDTypography>
          </MDBox>
        )}
        {noData && (
          <MDTypography ml={2} variant="caption" color="primary">
            {t("general:no_result_msg")}
          </MDTypography>
        )}
      </MDBox>
    </MDBox>
  );
};

const StyledIconButton = styled(IconButton)(() => ({}));

export default BOTable;
