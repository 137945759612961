import DashboardLayout from "components/LayoutContainers/DashboardLayout";
import MDBox from "components/MDBox";
import DashboardNavbar from "components/Navbars/DashboardNavbar";
import PageContentContainer from "components/PageContainers/PageContentContainer";
import PageHeaderContainer from "components/PageContainers/PageHeaderContainer";
import { useTranslation } from "react-i18next";
import { useCallback } from "react";
import BOTable from "components/BOTable/BOTable";
import { Tables } from "constants/tables";
import { ApiSearchResult } from "types/api";
import colors from "assets/theme/base/colors";
import { toastifySuccess } from "utils/toastify-message";

const fileURL = 'https://app.minereye.com/entities/minereye-regex-data.json';
const EntitiesPage = () => {
  const { t } = useTranslation();

  const onRowClicked = useCallback(async (cell?: any) => {
    if (cell?.row?.original && cell?.column?.id === 'copy') {
      if (cell?.row?.original && cell?.column?.id === 'copy') {
        await navigator.clipboard.writeText(cell?.row?.original.regex_pattern);
        toastifySuccess('Pattern copied');
      }
      }
  }, []);

  return (
    <DashboardLayout>
      <PageHeaderContainer>
        <DashboardNavbar pageTitle={t("entities:title")} />
      </PageHeaderContainer>
      <PageContentContainer component="div">
        <MDBox p={0.5} shadow="md">
        <BOTable
            tableWrapperStyle={{
              border: `1px solid ${colors.grey[100]}`
            }}
            table={Tables.ENTITIES}
            height="100%"
            tableKey="entities"
            onRowClicked={onRowClicked}
            fetchNextPage={async (): Promise<ApiSearchResult<unknown>> => {
              const res = await fetch(fileURL).then(r => r.json());
              return Promise.resolve({
                data: res || [],
                meta: { totalRowCount: res.count, page: 1 }
              });
          } }
          />
        </MDBox>
      </PageContentContainer>
    </DashboardLayout>
  );
};

export default EntitiesPage;
