import dayjs, { Dayjs } from "dayjs";
import { toSelectItem } from "./select.utils";
import { formatDateTime } from "components/TanstackTable/table.utils";

/**
 * @param date - default is current date
 * @returns the date in `DDMMYY` format
 */

const inputDateFormat = "DD/MM/YYYY";
export const getDateDDMMYY = (date?: string | number | Date): string => {
  return (date ? new Date(date) : new Date())
    .toLocaleDateString("he-IL", {
      day: "2-digit",
      month: "2-digit",
      year: "2-digit",
    })
    .replaceAll(".", "");
};

export const getDate = (date?: string | number | Date): string => {
  return (date ? new Date(date) : new Date()).toLocaleDateString("he-IL", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });
};

export const getDateDDMMYYYY = (date?: string | number | Date): string => {
  return (date ? new Date(date) : new Date())
    .toLocaleDateString("he-IL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    })
    .replaceAll(".", "/");
};

// without time
export const onlyDate = (date: Date | string | Dayjs, format?: string) => {
  return dayjs(date).format(format || "YYYY-MM-DD");
};

export const isDateBeforeOrSame = (
  a: Date | string | Dayjs,
  b: Date | string | Dayjs
): boolean => {
  const dateA = dayjs(a, inputDateFormat).toDate();
  const dateB = dayjs(b, inputDateFormat).toDate();
  return dateA.getTime() <= dateB.getTime();
};

export const isDateAfterOrSame = (
  a: Date | string,
  b: Date | string
): boolean => {
  const dateA = dayjs(a, inputDateFormat).toDate();
  const dateB = dayjs(b, inputDateFormat).toDate();
  return dateA.getTime() >= dateB.getTime();
};

export const isDateBetweenDates = (
  date: Date | string,
  minDate: Date | string,
  maxDate: Date | string
): boolean => {
  return isDateBeforeOrSame(minDate, date) && isDateAfterOrSame(maxDate, date);
};

export const getLastNMonths = (n: number) => {
  const result = [];
  for (let i = 1; i <= n; i++) {
    result.push(
      toSelectItem(
        dayjs().add(-i, "month").format("MMYYYY"),
        dayjs().add(-i, "month").format("MM/YYYY")
      )
    );
  }

  return result;
};

export const getDateAndHourObj = () => {
  return {
    date: formatDateTime(new Date()).split(" ")[0],
    hour: formatDateTime(new Date()).split(" ")[1],
  };
};

export const getLastMonth = () => {
  return dayjs().add(-1, "month").format("MMYYYY");
};

export const formatStringDate = (
  date: string,
  inputFormat: string,
  outputFormat: string
) => {
  const parsedDate = dayjs(date, inputFormat);
  const formattedDate = parsedDate.format(outputFormat);
  return formattedDate;
};

export const getStartOfLastMonth = () => {
  return dayjs().subtract(1, "month").startOf("month");
};

export const getStartOfLastMonthOfLastYear = () => {
  return dayjs().subtract(1, "year").subtract(1, "month").startOf("month");
};

export const getEndOfCurrentMonth = () => {
  return dayjs().endOf("month");
};
