import http from "services/http/http";
import { ApiSearchQueryParams } from "types/api.d";
import { ExportColumn } from "types/export-column";
import { getDateDDMMYY } from "./date.utils";
import { ExportFilter } from "types/export-filter";
import { formatDateTime } from "components/TanstackTable/table.utils";

export const exportFile = async (
  tableName: string,
  format: string,
  exportColumns: ExportColumn[],
  exportFilters: ExportFilter[],
  query: ApiSearchQueryParams,
  filters: any,
  fileName?: string
) => {
  try {
    const response = await http.request<string>({
      key: "export",
      params: { tableName, format: format },
      data: { exportColumns, exportFilters, filters, fileName, date: formatDateTime(new Date()) },
      queryParams: query,
    });
    const outputFilename = `${fileName || tableName}-${getDateDDMMYY(
      Date.now()
    )}.${format}`;

    downloadFile(response, outputFilename);
  } catch (error: any) {
    console.error(error);
  }
};

export const downloadFile = (data: string, outputFilename: string) => {
  // If you want to download file automatically using link attribute.
  const url = URL.createObjectURL(new Blob([data]));
  const link = document.createElement("a");
  link.href = url;
  link.setAttribute("download", outputFilename);
  document.body.appendChild(link);
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};

export const downloadFileFromUrl = (
  data: { url: string },
  outputFilename: string
) => {
  fetch(data.url)
    // check to make sure you didn't have an unexpected failure (may need to check other things here depending on use case / backend)
    .then((resp) =>
      resp.status === 200 ? resp.blob() : Promise.reject("something went wrong")
    )
    .then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.setAttribute("download", outputFilename);
      a.href = url;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      a.parentNode.removeChild(a);
      // or you know, something with better UX...
    });
};

export const downloadFile2 = (data: string, outputFilename: string) => {
  const link = document.createElement("a");
  link.href = data;
  link.setAttribute("download", outputFilename);
  document.body.appendChild(link);
  link.click();
  // Clean up and remove the link
  link.parentNode.removeChild(link);
};
