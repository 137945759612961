export const pdfSources = {
    aws: {
        pageTitle: 'navigation:procedures',
        link: 'https://app.minereye.com/installation-procedures/Services-AWS-070324-090405.pdf',
    },
    gcp: {
        pageTitle: 'navigation:procedures',
        link: 'https://app.minereye.com/installation-procedures/Services-Google+Cloud-070324-090534.pdf',
    },
    azure: {
        pageTitle: 'navigation:procedures',
        link: 'https://app.minereye.com/installation-procedures/Services-Google+Cloud-070324-090534.pdf',
    },
    vmware: {
        pageTitle: 'navigation:procedures',
        link: 'https://app.minereye.com/installation-procedures/Services-Google+Cloud-070324-090534.pdf',
    },
    dataTracker: {
        pageTitle: 'navigation:manuals',
        link: 'https://app.minereye.com/user-guides/Services-DataTracker%E2%84%A2+Configuration-070324-091526.pdf',
    },
    dashboard: {
        pageTitle: 'navigation:manuals',
        link: 'https://app.minereye.com/installation-procedures/Services-AWS-070324-090405.pdf',
    },
    boards: {
        pageTitle: 'navigation:manuals',
        link: 'https://app.minereye.com/user-guides/Services-Boards-070324-091628.pdf',
    },
    entities: {
        pageTitle: 'navigation:manuals',
        link: 'https://app.minereye.com/user-guides/Services-Managing%C2%A0entities%C2%A0-070324-091656.pdf',
    },
    zoom: {
        pageTitle: 'navigation:manuals',
        link: 'https://app.minereye.com/user-guides/Services-The+Zoom+page-070324-091735.pdf',
    },
    zoomRules: {
        pageTitle: 'navigation:manuals',
        link: 'https://app.minereye.com/user-guides/Services-Rules+and+Virtual+Labels-070324-091811.pdf',
    },
    interactiveCharts: {
        pageTitle: 'navigation:manuals',
        link: 'https://app.minereye.com/user-guides/Services-Visualization+and+interactive+charts-070324-102645.pdf',
    },
    archivedFiles: {
        pageTitle: 'navigation:manuals',
        link: 'https://app.minereye.com/user-guides/Services-Supported+archive+files-070324-102811.pdf',
    }
};

export type ContextType = keyof typeof pdfSources;