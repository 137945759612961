import {
  FilterConfig,
  FilterType,
  FiltersConfig,
} from "components/Tables/Filters/Filters";
import { TFunction } from "i18next";
import { dayjs } from "services/dayjs";
import { ApiSearchQueryParams } from "types/api";
import { ExportFilter } from "types/export-filter";

export const migrateSortModalFromTableToBE = (
  sorting: { id: string; desc: boolean }[]
) => {
  const sortingDirection = sorting?.length
    ? sorting[0]?.desc
      ? `-${sorting[0]?.id}`
      : `${sorting[0]?.id}`
    : "";
  return sortingDirection;
};

export const getApiSearchQueryParamsObj = (
  nextPage: number,
  size: number,
  sorting?: { id: string; desc: boolean }[],
  searchString?: string
): ApiSearchQueryParams => {
  return {
    orderBy: (sorting && sorting[0]?.id) || undefined,
    orderDirection: sorting?.length
      ? sorting[0]?.desc
        ? "desc"
        : "asc"
      : undefined,
    page: nextPage + 1 || undefined,
    pageSize: size || undefined,
    search: searchString || undefined,
  };
};

const formatDateTimeOptions: Intl.DateTimeFormatOptions = {
  day: "2-digit",
  month: "2-digit",
  year: "numeric",
  hour: "2-digit",
  minute: "2-digit",
  formatMatcher: "basic",
};

export const formatDateTime = (dateTime: string | Date | undefined): string => {
  if (!dateTime) {
    return "";
  }
  return new Date(dateTime.toString())
    .toLocaleString("he-IL", formatDateTimeOptions)
    .replaceAll(".", "/")
    .replace(",", "");
};

export const formatDate = (
  dateTime: string | Date | undefined,
  fromFormat?: string,
  toFormat?: string
): string => {
  if (!dateTime) {
    return "";
  }

  return dayjs(dateTime, fromFormat || "DD/MM/YYYY HH:mm:ss").format(
    toFormat || "DD/MM/YY"
  );
};

export const getExportFilters = (
  filtersConfig: FiltersConfig = {},
  filters: any,
  searchHeader: string,
  searchValue: string,
  t: TFunction
) => {
  const result = Object.keys(filtersConfig).map((fieldKey) => {
    const value = getFilterValue(filtersConfig[fieldKey], filters[fieldKey], t);
    return <ExportFilter>{
      header: filtersConfig[fieldKey].label,
      key: fieldKey,
      value: value || filtersConfig[fieldKey].placeholder || t("common:all"),
    };
  });

  if (searchHeader) {
    result.unshift({
      header: searchHeader,
      value: searchValue || t("common:all"),
      key: "search",
    });
  }

  return result;
};

const getFilterValue = (
  filterConfig: FilterConfig,
  value: any,
  t: TFunction
) => {
  switch (filterConfig.type) {
    case FilterType.checkbox:
      return t(`common:${value ? "yes" : "no"}`);
    case FilterType.multiSelect:
      return value && value?.length
        ? value
            .map(
              (value: string) =>
                filterConfig.options.find((opt) => opt.value == value).label
            )
            .join(", ")
        : value;
    case FilterType.datePicker:
      return formatDate(value);
    case FilterType.search:
    case FilterType.select:
      return (
        filterConfig.options.find(
          (opt) => opt.value?.toString() == value?.toString()
        )?.label || ""
      );
  }
};
