import React, { FC, useCallback } from 'react';
import { Column, ColumnOrderState, Header, Table } from '@tanstack/react-table';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { useDrag, useDrop } from 'react-dnd';
import MDBox from 'components/MDBox';

export const DraggableColumnHeader: FC<{
  header: Header<any, unknown>
  table: Table<any>
  children: React.ReactNode;
  visible: boolean;
}> = ({ header, table, children, visible }) => {
  const { getState, setColumnOrder } = table
  const { columnOrder } = getState()
  const { column } = header;

  const reorderColumn = useCallback((
    draggedColumnId: string,
    targetColumnId: string,
    columnOrder: string[]
  ): ColumnOrderState => {
    columnOrder.splice(
      columnOrder.indexOf(targetColumnId),
      0,
      columnOrder.splice(columnOrder.indexOf(draggedColumnId), 1)[0] as string
    )
    return [...columnOrder]
  }, []);
  

  const [, dropRef] = useDrop({
    accept: 'column',
    drop: (draggedColumn: Column<any>) => {
      const newColumnOrder = reorderColumn(
        draggedColumn.id,
        column.id,
        columnOrder,
      )
      setColumnOrder(newColumnOrder)
    },
  })

  const [{ isDragging }, dragRef, previewRef] = useDrag({
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    item: () => column,
    type: 'column',
    canDrag: visible
  })

  return (
    <th
      ref={dropRef}
      className={visible ? '' : 'cell-invisible-edit'}
      colSpan={header.colSpan}
      style={{
        width: header.getSize() + 24,
        textTransform: "capitalize",
        opacity: isDragging ? 0.5 : 1
      }}
    >
      <MDBox ref={previewRef} display="flex" justifyContent="space-between" alignItems="center" height={40}>
        {children}
        <span ref={dragRef}>
          <DragIndicatorIcon fontSize="small" />
        </span>
      </MDBox>
    </th>
  )
}