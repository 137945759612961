import React, { useState, useEffect, useMemo } from "react";

import "./BOTable.css";

import {
  CellContext,
  ColumnDef,
  flexRender,
  getCoreRowModel,
  Row,
  SortingState,
  useReactTable,
} from "@tanstack/react-table";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useVirtual } from "react-virtual";
import { ApiSearchQueryParams, ApiSearchResult } from "types/api";
import MDBox from "components/MDBox";
import { getApiSearchQueryParamsObj } from "./table.utils";
import { useTranslation } from "react-i18next";
import { useAppSelector } from "store/hooks";
import MDTypography from "components/MDTypography";
import { Card } from "@mui/material";
import pxToRem from "assets/theme/functions/pxToRem";
import { getIsMobile } from "store/slices/general";
import { ReactComponent as UnfoldMoreSVG } from "../../assets/Icons/unfoldMore.svg";
import { ReactComponent as SortUpIcon } from "../../assets/Icons/sortUp.svg";
import { ReactComponent as SortDownIcon } from "../../assets/Icons/sortDown.svg";
import { ReactComponent as ArrowDownSVG } from "../../assets/Icons/arrowDown.svg";
import { TableConfig } from "interfaces/table";
import { sortArrayByOrder } from "utils/array.util";
import { ReactComponent as VisibilityIcon } from 'assets/Icons/visibility-on.svg';
import { ReactComponent as VisibilityOffIcon } from 'assets/Icons/visibility-off.svg';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DraggableColumnHeader } from "./DraggableColumnHeader";
import cn from "classnames";

export interface UpdatedData<T> {
  id: string | number;
  value: T;
}

export interface TableProps<DataEntity extends unknown> {
  tableConfig: TableConfig;
  editMode: boolean;
  fetchData: (
    searchParams: ApiSearchQueryParams,
    filters?: any
  ) => Promise<ApiSearchResult<DataEntity>>;
  fetchSize: number;
  tableKey: string;
  searchString?: string;
  filters?: any;
  onRowClick?: Function;
  isReset?: boolean;
  striped?: boolean;
  onSettingsUpdate?: (v: TableConfig) => void;
  allowInlineFilter?: boolean;
  onInit?: (v: { refetch: () => void; table: any }) => void;
  noData?: boolean;
}

const Table = <DataEntity extends unknown>({
  tableConfig,
  editMode,
  fetchData,
  fetchSize,
  searchString,
  filters,
  isReset,
  tableKey,
  striped,
  onSettingsUpdate,
  onRowClick,
  noData,
  allowInlineFilter = true,
  onInit,
}: TableProps<DataEntity>) => {
  const isMobile = useAppSelector(getIsMobile);
  const tableContainerRef = React.useRef<HTMLDivElement>(null);
  const [tableSorting, setTableSorting] = useState<SortingState>([]);
  const [flatData, setFlatData] = useState<DataEntity[]>([]);

  const { data, fetchNextPage, isFetching, isLoading, refetch, hasNextPage } =
    useInfiniteQuery<ApiSearchResult<DataEntity>>(
      [tableKey, tableSorting, searchString, isReset, filters],
      async ({ pageParam = 0 }) => {
        const fetchedData = fetchData(
          getApiSearchQueryParamsObj(
            pageParam,
            fetchSize,
            tableSorting,
            searchString
          ),
          filters
        );
        return fetchedData;
      },
      {
        getNextPageParam: (_lastGroup, groups) => groups.length,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        cacheTime: 0,
      }
    );
  
  const columns = useMemo<ColumnDef<any>[]>(() => {
    const cols = Object.entries(tableConfig.columns).map(([key, value]) => ({
      accessorKey: key,
      cell: value.cell || ((info: CellContext<any, any>) => info.getValue()),
      accessorFn: value.accessorFn || (value.increment ? (_: any, index: number) => index + 1 : undefined),
      header: () => t(value.label),
      enableSorting: value.enableSorting,
      sortDescFirst: value.sortDescFirst,
      size: value.size || 130,
    }));

    return sortArrayByOrder(cols, tableConfig.order, 'accessorKey');
  }, [tableConfig]);

  const updateSettings = (settings: Partial<TableConfig>) => {
    onSettingsUpdate({
      ...tableConfig,
      columns: {
        ...tableConfig.columns,
        ...settings.columns || {},
      },
      order: settings.order || tableConfig.order,
    })
  }

  useEffect(() => {
    setFlatData(data?.pages?.flatMap((page) => page.data) ?? []);
  }, [data]);

  const handleSortingChanged = (sort: any) => {
    let _sortData = sort();
    if (tableSorting[0]?.desc) {
      _sortData = [];
    }
    setTableSorting(_sortData);
  };

  const { t } = useTranslation();

  const totalDBRowCount = data?.pages?.[0]?.meta?.totalRowCount ?? 0;
  const totalFetched = flatData.length;

  const fetchMoreOnBottomReached = React.useCallback(
    (containerRefElement?: HTMLDivElement | null) => {
      if (containerRefElement) {
        const { scrollHeight, scrollTop, clientHeight } = containerRefElement;
        if (
          scrollHeight - scrollTop - clientHeight < 150 &&
          !isFetching &&
          totalFetched < totalDBRowCount
        ) {
          fetchNextPage();
        }
      }
    },
    [fetchNextPage, isFetching, totalFetched, totalDBRowCount]
  );

  //a check on mount and after a fetch to see if the table is already scrolled to the bottom and immediately needs to fetch more data
  // React.useEffect(() => {
  //   fetchMoreOnBottomReached(tableContainerRef.current);
  // }, [fetchMoreOnBottomReached]);
  const [autoResetPageIndex, skipAutoResetPageIndex] = useSkipper();
  const table = useReactTable({
    data: flatData,
    columns,
    state: {
      sorting: tableSorting,
      columnOrder: tableConfig.order
    },
    onColumnOrderChange: (ord) => updateSettings({ order: ord as string[] }),
    onSortingChange: handleSortingChanged,
    getCoreRowModel: getCoreRowModel(),
    autoResetPageIndex,
    meta: {
      updateData: (rowIndex: number, columnId: string, value: any[]) => {
        skipAutoResetPageIndex();
        setFlatData((old) =>
          old.map((row: DataEntity, index: number) => {
            if (index === rowIndex) {
              return {
                ...(old[rowIndex] as any),
                [columnId]: value,
              };
            }
            return row;
          })
        );
      },
    },
  });

  useEffect(() => {
    onInit?.({ refetch, table })
  }, [onInit, table]);

  useEffect(() => {
    const columnObj = Object.entries(tableConfig.columns);
    const columns = columnObj.reduce((acc, cur) => ({
      ...acc,
      [cur[0]]: editMode ? true : cur[1].visible,
    }), {})
    table.setColumnVisibility(columns);
  }, [editMode]);

  const { rows } = table.getRowModel();

  const rowVirtualizer = useVirtual({
    parentRef: tableContainerRef,
    size: rows.length,
    overscan: 20,
  });
  const { virtualItems: virtualRows, totalSize } = rowVirtualizer;
  const paddingTop = virtualRows.length > 0 ? virtualRows?.[0]?.start || 0 : 0;
  const paddingBottom =
    virtualRows.length > 0
      ? totalSize - (virtualRows?.[virtualRows.length - 1]?.end || 0)
      : 0;
  if (isLoading) {
    return <MDBox pl={3} mt={2}>{t("tables:loading")}...</MDBox>;
  }

  return (
    <DndProvider backend={HTML5Backend}>
    <div className="p-2">
      <div className="h-2" />
      <div
        className="container"
        onScroll={(e) => fetchMoreOnBottomReached(e.target as HTMLDivElement)}
        ref={tableContainerRef}
        key={tableKey}
      >
        {!isMobile ? (
          <table>
            <thead>
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => {
                    const columnId = header.column.id;

                    const HeaderTypography = (
                      <MDTypography
                        textTransform="capitalize"
                        variant="caption2"
                        fontWeight="medium"
                        color="primary"
                      >
                        {flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                      </MDTypography>
                    );

                    const isColumnVisible = tableConfig.columns[header.id].visible;
                    const visibleIcon = isColumnVisible
                      ? <VisibilityIcon />
                      : <VisibilityOffIcon />

                    if (editMode) {
                      return (
                        <DraggableColumnHeader
                          key={header.id}
                          header={header}
                          table={table}
                          visible={isColumnVisible}
                          >
                            <div className="column-eye-icon" onClick={() => updateSettings({
                              columns: {
                                [header.id]: {
                                  ...tableConfig.columns[header.id],
                                  visible: !isColumnVisible
                                }
                              }
                            })}>
                              {visibleIcon}
                            </div>
                            <MDBox height="26px">
                              {HeaderTypography}
                            </MDBox>
                        </DraggableColumnHeader>
                      )
                    }
                    return (
                      <th
                        key={header.id}
                        colSpan={header.colSpan}
                        style={{
                          width: header.getSize(),
                          textTransform: "capitalize",
                          textAlign: "center",
                        }}
                      >
                        {header.isPlaceholder ? null : (
                          <div
                            className={
                              header.column.getCanSort()
                                ? header.column.getIsSorted()
                                  ? "cursor-pointer select-none table-header"
                                  : "cursor-pointer select-none notSorted table-header"
                                : "table-header"
                            }
                          >
                            {columnId === "index" ? (
                              HeaderTypography
                            ) : (
                              <MDBox
                                display="flex"
                                justifyContent="space-between"
                                alignItems="center"
                              >
                                <MDBox display="flex" alignItems="center">
                                  {tableConfig.columns[header.id].enableSorting ? <MDBox width="20px">
                                    {header.column.getIsSorted() === 'asc' &&  <div onClick={() => header.column.toggleSorting(true, true)}><SortUpIcon /></div>}
                                    {header.column.getIsSorted() === 'desc' &&  <div onClick={() => header.column.toggleSorting(false, true)}><SortDownIcon /></div>}
                                    {!header.column.getIsSorted() &&  <div onClick={() => header.column.toggleSorting(false, true)}><UnfoldMoreSVG /></div>}
                                  </MDBox> : null}
                                  {HeaderTypography}
                                </MDBox>
                              </MDBox>
                            )}
                          </div>
                        )}
                      </th>
                    );
                  })}
                </tr>
              ))}
            </thead>

            <tbody>
              {paddingTop > 0 && (
                <tr>
                  <td style={{ height: `${paddingTop}px` }} />
                </tr>
              )}
              {!noData ? virtualRows.map((virtualRow) => {
                const row = rows[virtualRow.index] as Row<DataEntity>;
                return (
                  <tr
                    key={row.id}
                    className={striped ? "striped" : ""}
                  >
                    {row.getVisibleCells().map((cell) => {
                      const { cellType, visible, cellMaxTextLength } = tableConfig.columns[cell.column.id];
                      if (cellType === 'input') {
                        return (
                          <td key={cell.id} className={visible ? '' : 'cell-invisible-edit'}>
                          {flexRender(
                            <MDBox
                              border="none"
                              sx={{ outline: 'none' }}
                              component="input"
                              onInput={(e) => {
                                if ((e.target as any).value.length >= cellMaxTextLength) {
                                  (e.target as any).value = (e.target as any).value.slice(0, cellMaxTextLength);
                                }
                              }}
                              defaultValue={(cell.row.original as any)?.[cell.column.id] as string}
                              onBlur={(e) => onRowClick?.(cell, { newValue: (e.target as any).value, oldValue: (cell.row.original as any)[cell.column.id] })}
                            />,
                            cell.getContext()
                          )}
                        </td>
                        )
                      }
                      if (cellType === 'link') {
                        return (
                          <td onClick={() => onRowClick?.(cell)} key={cell.id} className={visible ? '' : 'cell-invisible-edit'}>
                            <MDTypography
                              className="link"
                              sx={{ cursor: 'pointer' }}
                            >
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                            </MDTypography>
                        </td>
                        );
                      }
                      return (
                        <td onClick={() => onRowClick?.(cell)} key={cell.id} className={cn({
                          'cell-invisible-edit': !visible,
                          'cell-text-limited': cellMaxTextLength > 0,
                        })}>
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    })}
                  </tr>
                );
              }) : null}
              {paddingBottom > 0 && (
                <tr>
                  <td style={{ height: `${paddingBottom}px` }} />
                </tr>
              )}
            </tbody>
          </table>
        ) : (
          <>
            {paddingTop > 0 && (
              <MDBox style={{ height: `${paddingTop}px` }}></MDBox>
            )}
            {!noData ? virtualRows.map((virtualRow) => {
              const row = rows[virtualRow.index] as Row<DataEntity>;
              return (
                <Card style={{ margin: pxToRem(10) }}>
                  <MDBox display={"flex"} flexWrap={"wrap"} px={2} py={1}>
                    {row.getVisibleCells().map((cell, index) => {
                      const { cellType, cellOptions } = tableConfig.columns[cell.column.id];

                      const header = table.getHeaderGroups()[0].headers[index];
                      return (
                        <MDBox flex={"45%"} mr={"5%"} my={1} onClick={() => onRowClick?.(cell)}>
                          <MDTypography
                            color="secondary"
                            fontWeight="regular"
                          >
                            {flexRender(
                              header.column.columnDef.header,
                              header.getContext()
                            )}
                          </MDTypography>
                          <MDTypography color={cellType === 'link' ? 'blue' : "primary"} fontWeight="medium">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </MDTypography>
                        </MDBox>
                      );
                    })}
                  </MDBox>
                </Card>
              );
            }): null}
            {paddingBottom > 0 && (
              <MDBox style={{ height: `${paddingBottom}px` }}></MDBox>
            )}
          </>
        )}
      </div>
    </div>
    </DndProvider>
  );
};

export default Table;

function useSkipper() {
  const shouldSkipRef = React.useRef(true);
  const shouldSkip = shouldSkipRef.current;

  // Wrap a function with this to skip a pagination reset temporarily
  const skip = React.useCallback(() => {
    shouldSkipRef.current = false;
  }, []);

  React.useEffect(() => {
    shouldSkipRef.current = true;
  });

  return [shouldSkip, skip] as const;
}
