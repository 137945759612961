import pxToRem from "assets/theme/functions/pxToRem";
import { Checkbox } from "components/Checkbox/Checkbox";
import DatePickerValue from "components/DatePicker/DatePicker";
import MDBox from "components/MDBox";
import MultiSelect from "components/MultiSelect/MultiSelect";
import SelectInput from "components/Select/Select";
import dayjs from "dayjs";
import { SelectItem } from "types/select";

export enum FilterType {
  select,
  datePicker,
  search,
  multiSelect,
  checkbox,
}
export type FilterConfig = {
  style?: any;
  type: FilterType;
  options?: SelectItem[];
  placeholder?: string;
  noOptionsText?: string;
  disableClearable?: boolean;
  value?: any;
  label?: string;
  minDate?: dayjs.Dayjs;
  maxDate?: dayjs.Dayjs;
  defaultValue?: any;
  valueToString?: (value: any[]) => string;
};

export type FiltersConfig = { [key: string]: FilterConfig };

const FilterGetter = ({
  config,
  onChange,
  name,
  value,
}: {
  config: FilterConfig;
  onChange?: (value: any, name: string) => void;
  name: string;
  value?: any;
}) => {
  switch (config.type) {
    case FilterType.datePicker:
      return (
        <DatePickerValue
          {...config}
          name={name}
          onChange={(v: Date) => onChange(v, name)}
        />
      );
    case FilterType.multiSelect:
      return (
        <MultiSelect
          {...config}
          options={config.options}
          value={config.value}
          onChange={onChange}
          name={name}
          valueToString={config.valueToString}
        />
      );
    case FilterType.select:
      return (
        <SelectInput
          style={{ height: pxToRem(33), width: pxToRem(140), ...config.style }}
          options={config.options}
          placeholder={config.placeholder}
          label={config.label}
          noOptionsText={config.noOptionsText}
          disableClearable={config.disableClearable}
          value={value}
          onChange={(val) => {
            onChange(val, name);
          }}
        />
      );
    case FilterType.checkbox:
      return (
        <Checkbox
          value={config.value}
          label={config.label}
          onChange={(e, checked) => {
            onChange(checked, name);
          }}
        />
      );
  }
};

const FiltersRow = ({
  config,
  onFilterChange,
  filters,
}: {
  config: { [key: string]: FilterConfig };
  onFilterChange?: (value: any, name: string) => void;
  filters?: any;
}) => {
  return (
    <MDBox display="flex" rowGap={1.5} flexWrap="wrap" alignItems="center">
      {Object.keys(config).map((key) => (
        <MDBox
          maxWidth={config[key]?.style?.maxWidth ?? "140px"}
          marginLeft="12px"
          key={key}
        >
          <FilterGetter
            value={filters[key]}
            config={config[key]}
            name={key}
            onChange={onFilterChange}
          />
        </MDBox>
      ))}
    </MDBox>
  );
};

export default FiltersRow;
