import { TableConfig } from "interfaces/table";

import DownloadIcon from '@mui/icons-material/Download';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import MDBox from "components/MDBox";

export enum Tables {
  BOARDS = 'Tables.BOARDS',
  ENTITIES = 'Tables.ENTITIES'
};

export const tablesConfigs: Record<Tables, TableConfig> = {
  [Tables.BOARDS]: {
    order: [],
    columns: {
      board_name:  {
        label: "tables:boards:name",
        visible: true,
        enableSorting: false,
        sortDescFirst: true,
        size: 200,
      },
      download:  {
        label: "tables:boards:download",
        visible: true,
        enableSorting: false,
        sortDescFirst: true,
        size: 10,
        cellType: 'link',
        accessorFn: () => <MDBox mx={4}> <DownloadIcon /></MDBox>
      },
    }
  },
  [Tables.ENTITIES]: {
    order: [],
    columns: {
      regex_name:  {
        label: "tables:entities:name",
        visible: true,
        enableSorting: false,
        sortDescFirst: true,
        size: 80,
      },
      regex_pattern:  {
        label: "tables:entities:pattern",
        visible: true,
        enableSorting: false,
        sortDescFirst: true,
        size: 260,
      },
      copy:  {
        label: "tables:entities:copy",
        visible: true,
        enableSorting: false,
        sortDescFirst: true,
        size: 10,
        cellType: 'link',
        accessorFn: () => <MDBox mx={2}> <ContentCopyIcon /></MDBox>
      },
    }
  }
}
