/** 
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";
// import * as dashboard from "assets/Icons/dashboard.svg";

import React from "react";
import { ReactComponent as UsersIcon } from "assets/Icons/userNavLinkIcon.svg";
import { ReactComponent as UsersIcon2 } from "assets/Icons/userNavLinkIcon2.svg";
import { ReactComponent as InstallationIcon } from "assets/Icons/installationNavLinkIcon.svg";
import { ReactComponent as ManualIcon } from "assets/Icons/manualNavLinkIcon.svg";
import { ReactComponent as LeadsIcon } from "assets/Icons/leadsNavLinkIcon.svg";
import { ReactComponent as MonthlyFrameworkIcon } from "assets/Icons/monthlyFrameworkIcon.svg";
import { ReactComponent as DeliveryOrdersIcon } from "assets/Icons/deliveryOrdersIcon.svg";
import { ReactComponent as DownloadIcon } from "assets/Icons/downloadIcon.svg";
import { ReactComponent as FinancialDataIcon } from "assets/Icons/financialDataIcon.svg";
import { ReactComponent as MastersDataIcon } from "assets/Icons/mastersDataIcon.svg";
import { ReactComponent as PublicationsIcon } from "assets/Icons/publicationsIcon.svg";
import { ReactComponent as ToolsIcon } from "assets/Icons/toolsIcon.svg";
import { ReactComponent as ContactUsIcon } from "assets/Icons/contactUsIcon.svg";
import { ReactComponent as CustomerIcon } from "assets/Icons/customerIcon.svg";
import { ReactComponent as MessagesIcon } from "assets/Icons/messagesIcon.svg";
import { ReactComponent as FileIcon } from "assets/Icons/fileIcon.svg";
import { ReactComponent as PlusWhite } from "assets/Icons/plusWhite.svg";
import TermsAndConditionsIcon from "@mui/icons-material/Description";
import CodeIcon from '@mui/icons-material/Code';
import CategoryIcon from '@mui/icons-material/Category';
import VisibilityIcon from '@mui/icons-material/Visibility';

// Images
import Login from "layouts/authentication/Login/Login";
import { Route } from "interfaces/infra";
import ResetPassword from "layouts/authentication/ResetPassword/ResetPassword";
import ForgetPassword from "layouts/authentication/ForgetPassword/ForgetPassword";
import HomePage from "layouts/Main/Home/Home";
import PDFFIleReaderPage from "layouts/Main/PDFFileReader/PDFFileReader";
import UpdateUserDetailsPage from "layouts/Main/UpdateUserDetails/UpdateUserDetails";
import BoardsPage from "layouts/Main/Boards/Boards";
import EntitiesPage from "layouts/Main/Entities/Entities";

const mainRoutes: Route[] = [
  {
    type: "collapse",
    noCollapse: true,
    href: "",
    route: "/home",
    name: "home",
    key: "home",
    component: <HomePage />,
    shouldBeAssosiated: false,
    icon: <UsersIcon2 />,
    activeIcon: <UsersIcon />,
    role: [],
  },
  {
    type: "collapse",
    noCollapse: false,
    href: "",
    collapse: [{
      type: "collapse",
      noCollapse: false,
      href: "",
      route: "/installation-procedures/aws",
      name: "AWS",
      key: "AWS",
      component: <PDFFIleReaderPage context="aws" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    },
    {
      type: "collapse",
      noCollapse: false,
      href: "",
      route: "/installation-procedures/gcp",
      name: "GCP",
      key: "GCP",
      component: <PDFFIleReaderPage context="gcp" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    },
    {
      type: "collapse",
      noCollapse: false,
      href: "",
      route: "/installation-procedures/azure",
      name: "AZURE",
      key: "AZURE",
      component: <PDFFIleReaderPage context="azure" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    },
    {
      type: "collapse",
      noCollapse: false,
      href: "",
      route: "/installation-procedures/vmware",
      name: "VMWARE",
      key: "VMWARE",
      component: <PDFFIleReaderPage context="vmware" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    }],
    route: "/installation-procedures",
    name: "installation-procedures",
    key: "installation-procedures",
    icon: <InstallationIcon />,
    shouldBeAssosiated: false,
    role: [],
  },
  {
    type: "collapse",
    noCollapse: false,
    href: "",
    route: "/manuals",
    name: "manuals",
    key: "manuals",
    icon: <VisibilityIcon />,
    shouldBeAssosiated: false,
    role: [],
    collapse: [{
      type: "collapse",
      noCollapse: true,
      href: "",
      route: "/manuals/data-tracker",
      name: "data-tracker",
      key: "data-tracker",
      component: <PDFFIleReaderPage context="dataTracker" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    },
    {
      type: "collapse",
      noCollapse: true,
      href: "",
      route: "/manuals/dashboard",
      name: "dashboard",
      key: "dashboard",
      component: <PDFFIleReaderPage context="dashboard" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    },
    {
      type: "collapse",
      noCollapse: true,
      href: "",
      route: "/manuals/boards",
      name: "boards",
      key: "boards",
      component: <PDFFIleReaderPage context="boards" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    },
    {
      type: "collapse",
      noCollapse: true,
      href: "",
      route: "/manuals/entities",
      name: "manual-entities",
      key: "entities",
      component: <PDFFIleReaderPage context="entities" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    },
    {
      type: "collapse",
      noCollapse: true,
      href: "",
      route: "/manuals/zoom",
      name: "zoom",
      key: "zoom",
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
      collapse: [
        {
          type: "collapse",
          noCollapse: false,
          href: "",
          route: "/manuals/zoom/page",
          name: "zoom-page",
          key: "zoom-page",
          component: <PDFFIleReaderPage context="zoom" />,
          icon: <ManualIcon />,
          shouldBeAssosiated: false,
          role: [],
        },
        {
          type: "collapse",
          noCollapse: false,
          href: "",
          route: "/manuals/zoom/zoom-rules",
          name: "zoom-rules",
          key: "zoom-rules",
          component: <PDFFIleReaderPage context="zoomRules" />,
          icon: <ManualIcon />,
          shouldBeAssosiated: false,
          role: [],
        },
        {
          type: "collapse",
          noCollapse: false,
          href: "",
          route: "/manuals/zoom/interactive-charts",
          name: "interactive-charts",
          key: "interactive-charts",
          component: <PDFFIleReaderPage context="interactiveCharts" />,
          icon: <ManualIcon />,
          shouldBeAssosiated: false,
          role: [],
      }]
    },
   
    {
      type: "collapse",
      noCollapse: false,
      href: "",
      route: "/manuals/archive-files",
      name: "archive-files",
      key: "archive-files",
      component: <PDFFIleReaderPage context="archivedFiles" />,
      icon: <ManualIcon />,
      shouldBeAssosiated: false,
      role: [],
    }],
  },
  
  {
    type: "",
    noCollapse: true,
    href: "",
    route: "/update-details",
    name: "manuals",
    key: "manuals",
    component: <UpdateUserDetailsPage />,
    icon: <ManualIcon />,
    shouldBeAssosiated: false,
    role: [],
  },
  {
    type: "collapse",
    noCollapse: true,
    href: "",
    route: "/boards-list",
    name: "boards",
    key: "boards",
    component: <BoardsPage />,
    shouldBeAssosiated: false,
    icon: <CategoryIcon />,
    role: [],
  },
  {
    type: "collapse",
    noCollapse: true,
    href: "",
    route: "/entities-list",
    name: "Entities",
    key: "Entities",
    component: <EntitiesPage />,
    shouldBeAssosiated: false,
    icon: <CodeIcon />,
    role: [],
  },
];

const routes = (isMobile: boolean): Route[] => mainRoutes;

export const authRoutes = [
  {
    key: "/login",
    component: <Login />,
  },
  {
    key: "/resetPassword",
    component: <ResetPassword />,
  },
  {
    key: "/forgetPassword",
    component: <ForgetPassword />,
  },
];

export default routes;
