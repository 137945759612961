export function getFileExtension(filename: string) {
  const lastDotIndex = filename.lastIndexOf(".");
  if (lastDotIndex === -1) {
    return "";
  }
  return filename.slice(lastDotIndex + 1);
}

export function firstCharToLowerCase(str: string): string {
  return str.charAt(0).toLowerCase() + str.slice(1);
}